@-webkit-keyframes ripple-animation {
  from {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes ripple-animation {
  from {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}

.ripple-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  body.no-page-transitions & {
    display: none;
  }

  span {
    position: absolute;
    display: block;
    background-color: var(--ripple-color, rgba(0, 0, 0, 0.08));
    border-radius: 50%;
    transform: scale(0);
    animation: ripple-animation 700ms;
    pointer-events: none;
  }
}
