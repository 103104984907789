.Auth {
  height: 100%;
}

.auth-form {
  width: 100%;
  max-width: 25.5rem;
  margin: 0 auto;
  padding: 6rem 1rem 1rem;
  text-align: center;

  @media (min-width: 600px) and (min-height: 450px) {
    padding: 6.8125rem 1.5rem 1.5rem;

    &.qr {
      padding-top: 4rem;
    }
  }

  #logo,
  .AvatarEditable label {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 7.5rem;
    height: 7.5rem;
    margin-bottom: 1.75rem;

    @media (min-width: 600px) and (min-height: 450px) {
      width: 10rem;
      height: 10rem;
      margin-bottom: 2.5rem;
    }

    body.is-electron & {
      width: 6rem;
      height: 6rem;
      margin-bottom: 1.75rem;
    }
  }

  #logo {
    background: url("../../assets/telegram-logo.svg") center no-repeat;
    background-size: 100%;
  }

  h1 {
    font-size: 1.25rem;
    line-height: 1;

    @media (min-width: 600px) {
      font-size: 2rem;
      line-height: 1.5;
    }
  }

  .note {
    font-size: 0.875rem;
    line-height: 1.35;
    margin-bottom: 2.5rem;
    color: var(--color-text-secondary);

    @media (min-width: 600px) {
      font-size: 1rem;
      margin-bottom: 3rem;
    }
  }

  body.is-electron #auth-phone-number-form & {
    padding-top: 3rem;

    .form {
      min-height: 0;
    }
  }
}

#auth-registration-form,
#auth-phone-number-form,
#auth-code-form,
#auth-password-form,
#auth-qr-form {
  height: 100%;
  overflow-y: auto;

  body.is-electron.is-macos & {
    -webkit-app-region: drag;

    .input-group {
      -webkit-app-region: no-drag;
    }
  }
}

#auth-phone-number-form {
  .form {
    min-height: 26.25rem;
  }

  .Button {
    margin-top: 2.75rem;
  }

  .Button + .Button {
    margin-top: 1rem;
  }
}

#auth-code-form,
#auth-password-form {
  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.auth-number-edit {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  color: var(--color-text-secondary);
  opacity: 0.75;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: var(--custom-cursor, pointer);

  &:hover,
  &:focus {
    opacity: 1;
  }
}

#auth-qr-form {
  .qr-outer {
    height: 280px;
    position: relative;
  }

  .qr-inner,
  .qr-loading {
    height: 280px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
  }

  .qr-inner {
    transition: none !important;
    &.open {
      animation: qr-show 400ms ease-in-out forwards;
    }

    &:not(.shown) {
      display: none;
    }
  }

  .qr-loading {
    transition: opacity 1ms;
  }

  .qr-plane {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 50%;
    background-color: var(--color-telegram-blue);
  }

  .qr-container {
    background-color: white;
    border-radius: 1.5rem;
    overflow: hidden;
  }

  h1 {
    margin: 1.5rem 0 1rem 0;
    font-size: 1.25rem;
  }

  ol {
    list-style: none;
    counter-reset: item;
    padding: 0 1.75rem;

    li {
      counter-increment: item;
      text-align: left;
      margin: 0.75rem 0;
      display: flex;

      &::before {
        content: counter(item);
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 1.375rem;
        height: 1.375rem;
        padding: 0;
        margin: 0 0.75rem 0 0;
        background: var(--color-primary);
        border-radius: 50%;
        font-size: smaller;
        color: white;
      }
    }
  }
}

#sign-in-password {
  word-break: normal !important;
}

@keyframes qr-show {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
