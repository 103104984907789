.Menu {
  &.fluid .bubble {
    min-width: 13.5rem;
    width: auto;
  }

  .backdrop {
    position: fixed;
    left: -100vw;
    right: -100vw;
    top: -100vh;
    bottom: -100vh;
    z-index: var(--z-menu-backdrop);
  }

  .bubble {
    overflow: hidden;
    display: block;
    list-style: none;
    padding: 0.5rem 0;
    margin: 0;
    position: absolute;
    background-color: var(--color-background);
    box-shadow: 0 0.25rem 0.5rem 0.125rem var(--color-default-shadow);
    border-radius: var(--border-radius-default);
    min-width: 13.5rem;
    z-index: var(--z-menu-bubble);
    overscroll-behavior: contain;
    color: var(--color-text);

    transform: scale(0.85);
    transition: opacity 150ms cubic-bezier(0.2, 0, 0.2, 1), transform 150ms cubic-bezier(0.2, 0, 0.2, 1) !important;

    &.open {
      transform: scale(1);
    }

    &.closing {
      transition: opacity 0.2s ease-in, transform 0.2s ease-in !important;
    }

    &.close-fast {
      transition-duration: 70ms !important;
    }

    body.no-context-menu-animations & {
      transform: none !important;
      transition: opacity 0.15s !important;
    }

    --offset-y: calc(100% + 0.5rem);
    --offset-x: 0;

    &.top {
      top: var(--offset-y);
    }

    &.bottom {
      bottom: var(--offset-y);
    }

    &.left {
      left: var(--offset-x);
    }

    &.right {
      right: var(--offset-x);
    }

    &.with-footer {
      padding-bottom: 0;
    }
  }

  body.has-open-dialog &:not(.with-menu-transitions) .bubble {
    transition: none !important;
  }

  .footer {
    padding: 0.5rem 0;
    background: var(--color-chat-hover);
    color: var(--color-text-secondary);
    font-size: 0.8125rem;
    text-align: center;
  }

  &.compact {
    .bubble {
      background: var(--color-background-compact-menu);
      backdrop-filter: blur(10px);
      padding: 0.25rem 0;

      body.no-menu-blur & {
        background: var(--color-background);
        backdrop-filter: none;
      }
    }

    .footer {
      background: none;
    }

    &.no-blur .bubble {
      background: var(--color-background);
    }
  }

  .menu-loading-row {
    margin: 0.125rem 1rem;
    width: calc(100% - 2rem);
  }

  &.in-portal {
    z-index: var(--z-portal-menu);
  }
}
